<ng-container *ngIf="!displayCategoryTextInput(); else createPropertyTextInput">
  <span class="centered category-container">
    {{ categoryName | translate }}
    <i class="modus-icons edit-icon"
       aria-hidden="true"
       *ngIf="displayEditButton()"
       (click)="onEditIconClick()">
      pencil
    </i>
  </span>
</ng-container>
<ng-template #createPropertyTextInput>
  <div class="property-text-input">
    <modus-text-input placeholder="{{ 'categoryName' | translate }}"
                      clearable="true"
                      value="{{currentCategoryValue}}"
                      max-length="25"
                      #categoryTextInput
                      (valueChange)="onTextInputChange(categoryTextInput.value)">
    </modus-text-input>
  </div>
</ng-template>
