import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {concatMap, from, Observable, of, Subject} from 'rxjs';
import {DocumentDashboardComponent} from '../../document-dashboard/document-dashboard.component';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';
import {FileKeyProperties, PropertyKey, PropertyRequest, PropertyValue} from '../../../models/fileKeyProperties.model';
import {DMSConfigService} from '../../../services/dmsconfig.service';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {FileMetadata} from '../../../models/file-metadata.model';
import { ExtendValues, GetParameters, ParameterSortKey, ParameterSortOrder, PropertyDataService } from 'src/app/services/property-data.service';
import { TableConfig } from '../table/table-config/table-config';
import { CommonConfig } from '../config/common-config';

@Component({
  selector: 'app-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.css']
})
export class AssignmentDialogComponent implements OnInit {
  static assignmentDoneEvent = new EventEmitter<FileMetadata>();

  @ViewChild('scrollDiv', {read: ElementRef}) private readonly scrollDiv: ElementRef;

  private assignmentURL = `${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/files/`;
  selectedValues: Set<string> = new Set();

  properties: FileKeyProperties[] = [];
  selectedPropertyValues: FileKeyProperties[] = [];
  selectedPropVals: { [key: number]: PropertyValue[] } = {};
  private itemsToAdd: Set<PropertyValue> = new Set();
  private initSelectedPropVals: { [key: number]: PropertyValue[] } = {};
  private itemsToRemove: Set<PropertyValue> = new Set();
  private readonly stop$: Subject<void> = new Subject<void>();
  propertyValues: {id: number, key: any, value: PropertyValue}[] = [];
  tableConfig = new TableConfig();
  commonConfig = new CommonConfig();
  filteredPropertyValues: { key: PropertyKey, value: PropertyValue }[] = [];
  private readonly PAGE_SIZE = 20;
  private readonly FIRST_PAGE = 0;
  private readonly COLUMN_OPTIONS_SORT_PROPERTY = ParameterSortKey.ID;
  private readonly COLUMN_OPTIONS_SORT_ORDER = ParameterSortOrder.ASC;
  searchTerm: string = '';


  constructor(
    public dialogRef: MatDialogRef<AssignmentDialogComponent>,
    private readonly handleErrorService: HandleErrorService,
    private readonly translationService: CustomTranslateService,
    private readonly dmsConfigService: DMSConfigService,
    private readonly docMgmtService: DocMgmtService,
    private readonly propertyDataService: PropertyDataService,
    private readonly  translateService: CustomTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      propertyValuesList: FileKeyProperties[],
      fileId: number,
      selectedPropVals: any
      fileName: string,
      fileKey: string,
    },
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    this.tableConfig.infiniteScrollEnabled = true;
    this.properties = this.data.propertyValuesList.sort((fileProp1, fileProp2) => {
      if (!fileProp1.key || !fileProp2.key) {
        return 0;
      }
      return fileProp1.key.toString().localeCompare(fileProp2.key.toString());
    });
  }

  assignmentForm = this.fb.group({
    selectedPropVals: [this.selectedPropVals],
  });

  ngOnInit(): void {
    this.resetScroll();
    if (this.data.selectedPropVals && this.data.selectedPropVals.length > 0) {
      this.data.selectedPropVals.forEach((prop) => {
      const vals = [...prop.values];
      this.selectedPropertyValues.push({ id: prop.key.id, key: prop.key.key, values: vals });
      this.selectedPropVals[prop.key.id] = vals;
      this.initSelectedPropVals[prop.key.id] = [...vals];
      vals.forEach(val => {
        val.value = this.translationService.getTranslatedValue(val.value);
        this.selectedValues.add(val.value);
      });
      });
    }
    this.loadKeys$({
      extend: [ExtendValues.VALUES],
      page: this.FIRST_PAGE,
      size: this.PAGE_SIZE,
      sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
    }).pipe(
      takeUntil(this.stop$)
    ).subscribe();
  }
  
  private loadKeys$(parameters: GetParameters): Observable<FileKeyProperties[]> {
    return this.propertyDataService.getValuesForAllKeys(parameters).pipe(
      tap(this.handleFileKeyPropertiesResponse)
    );
  }
  
  private handleFileKeyPropertiesResponse = (responses: FileKeyProperties[]): void => {
    const newSamples = responses.flatMap(prop => prop.values.map(val => ({
      id: prop.id,
      key: prop.key,
      value: val
    })));
    const propertyValuesMap = new Map<string, any>();
    this.addToPropertyValuesMap(this.propertyValues, propertyValuesMap);
    this.addToPropertyValuesMap(newSamples, propertyValuesMap);
    this.propertyValues = Array.from(propertyValuesMap.values());
    this.filteredPropertyValues = [...this.propertyValues];
  }
  
  private addToPropertyValuesMap(items: any[], propertyValuesMap: Map<string, any>): void {
    items.forEach(item => {
      const translatedKey = this.translationService.getTranslatedValue(item.key);
      const translatedValue = this.translationService.getTranslatedValue(item.value.value);
      propertyValuesMap.set(translatedValue, { 
        ...item, 
        key: translatedKey, 
        value: { ...item.value, value: translatedValue } 
      });
    });
  }
  
  onScroll(event: any): void {
    if (this.tableConfig.infiniteScrollEnabled) {
      this.commonConfig.pageNumber++;
      const searchValue = this.constructSearchValue(this.searchTerm);
  
      this.loadKeys$({
        extend: [ExtendValues.VALUES],
        page: this.commonConfig.pageNumber,
        size: this.PAGE_SIZE,
        sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
        search: searchValue
      }).pipe(
        takeUntil(this.stop$)
      ).subscribe(() => {
        this.filterPropertyValues(this.searchTerm);
      });
    }
  }
  
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.commonConfig.pageNumber = this.FIRST_PAGE;
    const searchValue = this.constructSearchValue(searchTerm);
  
    this.loadKeys$({
      extend: [ExtendValues.VALUES],
      page: this.commonConfig.pageNumber,
      size: this.PAGE_SIZE,
      sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
      search: searchValue
    }).pipe(
      takeUntil(this.stop$)
    ).subscribe(() => {
      this.filterPropertyValues(searchTerm);
    });
  }
  
  private constructSearchValue(searchTerm: string): string {
    if (!searchTerm.trim()) {
      return '';
    }
    const searchProperties = ['propertyValues.value', 'key'];
    const cleanedSearch = searchTerm.trim();
    return searchProperties.map(property => `${property}:*${cleanedSearch}*`).join(' OR ');
  }
  
  private filterPropertyValues(searchTerm: string): void {
    this.filteredPropertyValues = this.propertyValues.filter(option =>
      option.value.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.key.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

logOptionValue(value: string, checked: boolean): void {
  if (checked) {
    this.selectedValues.add(value);
  } else {
    this.selectedValues.delete(value);
  }
  this.updateSelectedProperties();
}

private updateSelectedProperties(): void {
  const updatedPropVals: { [key: number]: { id: number, value: string }[] } = {};
  const selectedPropertyValuesMap = new Map<number, { id: number, value: string }[]>();
  this.selectedPropertyValues.forEach(prop => {
    selectedPropertyValuesMap.set(prop.id, prop.values.filter(val => this.selectedValues.has(val.value)));
  });
  this.addNewSelectedValues(selectedPropertyValuesMap);
  selectedPropertyValuesMap.forEach((values, key) => {
    updatedPropVals[key] = values;
  });
  this.selectedPropVals = updatedPropVals;
  this.assignmentForm.patchValue({ selectedPropVals: JSON.parse(JSON.stringify(this.selectedPropVals)) });
}

private addNewSelectedValues(selectedPropertyValuesMap: Map<number, { id: number, value: string }[]>): void {
  this.selectedValues.forEach(value => {
    if (!this.isValueInSelectedPropertyValuesMap(selectedPropertyValuesMap, value)) {
      const newProp = this.propertyValues.find(sampleProp => sampleProp.value.value === value);
      if (newProp) {
        const existingValues = selectedPropertyValuesMap.get(newProp.key.id) || [];
        existingValues.push({ id: newProp.value.id, value: newProp.value.value });
        selectedPropertyValuesMap.set(newProp.key.id, existingValues);
      }
    }
  });
}

private isValueInSelectedPropertyValuesMap(selectedPropertyValuesMap: Map<number, { id: number, value: string }[]>, value: string): boolean {
  for (const values of selectedPropertyValuesMap.values()) {
    if (values.some(val => val.value === value)) {
      return true;
    }
  }
  return false;
}

  private readonly resetScroll = (): void => {
    if (this.scrollDiv !== undefined) {
      this.scrollDiv.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  onCloseClick(propId: number, propValId: number): void {
    const propKey = this.selectedPropertyValues.find((prop) => prop.id === propId);
    if (propKey) {
      const removedValues = propKey.values.filter(val => val.id === propValId);
      propKey.values = propKey.values.filter(val => val.id !== propValId);
      this.selectedPropVals[propId] = propKey.values;
      this.assignmentForm.value.selectedPropVals = this.selectedPropVals;
      // Track items to remove
      removedValues.forEach(val => this.itemsToRemove.add(val));
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAssign(): void {
    this.preparePropertyValues();
    const selectedPropVals = this.assignmentForm.value.selectedPropVals;
    const fileId = this.data.fileId;
    if (this.itemsToAdd?.size > 0 || this.itemsToRemove?.size > 0) {
      const requests = [];
      if (this.itemsToAdd?.size > 0) {
        requests.push(...this.assignPropertyValues(fileId, Array.from(this.itemsToAdd)));
      }
      if (this.itemsToRemove?.size > 0) {
        requests.push(...this.unAssignPropertyValues(fileId, Array.from(this.itemsToRemove)));
      }
      from(requests).pipe(
        concatMap(request => request.pipe(
          catchError(err => {
            console.error('Error in processing the assignment:', err);
            this.handleErrorService.displayErrorDialog(this.translationService.getTranslatedValue('unableToProcess'), 'Process Error');
            return of(null);
          })
        ))
      ).subscribe({
                    complete: () => {
                      const updateFileMedatada = this.prepareFileMetadataForPropagation(this.data.fileKey, selectedPropVals);
                      AssignmentDialogComponent.assignmentDoneEvent.emit(updateFileMedatada);
                      this.dialogRef.close(selectedPropVals);
                      DocumentDashboardComponent.refreshEvent.emit();
                    }
                  });
    } else {
      this.dialogRef.close();
      DocumentDashboardComponent.refreshEvent.emit();
    }
  }

  private buildFileProperties(selectedPropVals: { [key: number]: PropertyValue[] }): FileKeyProperties[] {
    const updateProperties: FileKeyProperties[] = [];
    Object.keys(selectedPropVals).forEach(keyId => {
      const property = this.properties.find(prop => prop.id === +keyId);
      if (property && selectedPropVals[keyId].length > 0) {
        const filePropertyKey: PropertyKey = {
          id: property.id,
          global: false,
          key: property.key.key
        };
        updateProperties.push({
          id: +keyId,
          key: filePropertyKey,
          values: selectedPropVals[keyId]
        });
      }
    });
    return updateProperties;
  }

  private getUpdatedFileMetadata(fileKey: string, fileProperties: FileKeyProperties[], filesData: FileMetadata[]): FileMetadata {
    return this.updateFileProperties(fileKey, filesData, fileProperties);
  }

  private prepareFileMetadataForPropagation(fileKey: string, selectedPropVals: { [key: number]: PropertyValue[] }): FileMetadata {
    const fileProperties = this.buildFileProperties(selectedPropVals);
    // The file table data is formed in 2 parts, one is the fileMetadata and the other is fullFileMetadata
    // For updating the file properties, we need to check in both lists
    let updatedFileMetadata = this.getUpdatedFileMetadata(fileKey, fileProperties, this.docMgmtService.fileMetadata.value);
    if (!updatedFileMetadata) {
      updatedFileMetadata = this.getUpdatedFileMetadata(fileKey, fileProperties, this.docMgmtService.fullFileMetadata.value);
    }
    return updatedFileMetadata;
  }

  private updateFileProperties(fileKey: string, filesData: FileMetadata[], fileProperties: FileKeyProperties[]): FileMetadata {
    const fileMetadata = filesData.find(file => file.fileKey === fileKey);
    if (fileMetadata) {
      fileMetadata.properties = fileProperties;
    }
    return fileMetadata;
  }

  /**
   * Prepare the property values to be assigned or unassigned
   * based on the changes made by the user
   * @private
   */
  private preparePropertyValues() {
      this.itemsToAdd.clear();
      this.itemsToRemove.clear();
      const selectedPropVals = this.assignmentForm.value.selectedPropVals || {};
      Object.keys(selectedPropVals).map((propId) => {
        const propVals = selectedPropVals[propId];
        const initialPropVals = this.initSelectedPropVals[propId] || [];
        propVals.forEach((val) => {
          if (!initialPropVals.find((initialVal) => initialVal.id === val.id) && !this.itemsToAdd.has(val)) {
            this.itemsToAdd.add(val);
          }
        });
        initialPropVals.forEach((initialVal) => {
          if (!propVals.find((val) => val.id === initialVal.id)) {
            this.itemsToRemove.add(initialVal);
          }
        });
      });
  }

  private assignPropertyValues(fileId: number, filePropertyValues: PropertyValue[]): Observable<any> [] {
    const requests = [];
    filePropertyValues.forEach((value: { id: number; }) => {
      const formData: PropertyRequest = {valueId: value.id};
      const request = this.http.post(`${this.assignmentURL}${fileId}/property-values`, formData).pipe(
        catchError(err => {
            console.error(`Error in assigning property value:`, err);
            return of(null); // Return a null observable to continue the forkJoin
          }
        )
      );
      requests.push(request);
    });
    return requests;
  }

  private unAssignPropertyValues(fileId: number, filePropertyValues: PropertyValue[]): Observable<any> [] {
    const requests = [];
    filePropertyValues.forEach((value: { id: number; }) => {
      const request = this.http.delete(`${this.assignmentURL}${fileId}/property-values/${value.id}`).pipe(
        catchError(err => {
            console.error(`Error in unassigning property value:`, err);
            return of(null); // Return a null observable to continue the forkJoin
          }
        )
      );
      requests.push(request);
    });
    return requests;
  }
}
