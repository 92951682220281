import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum TagHeaderEventType {
  PROPERTY_CREATION_REQUEST,
  PROPERTY_SEARCH_REQUEST
}

export interface TagHeaderEvent {
  type: TagHeaderEventType;
}

@Injectable()
export class TagHeaderService {

  private readonly tagHeaderEventSubject = new Subject<TagHeaderEvent>();

  public tagHeaderEvent$(): Observable<TagHeaderEvent> {
    return this.tagHeaderEventSubject.asObservable();
  }

  public publishTagHeaderEvent(event: TagHeaderEvent) {
    this.tagHeaderEventSubject.next(event);
  }

  public publishTagHeaderSearchEvent(input?: string) {
    this.publishTagHeaderEvent({
      type: TagHeaderEventType.PROPERTY_SEARCH_REQUEST,
      payload: input || ''
    } as TagHeaderEvent & { payload: string });
  }
  
}
