import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PropertyKey} from '../../../../models/fileKeyProperties.model';
import {
  ExtendValues,
  GetParameters,
  ParameterSortKey,
  ParameterSortOrder,
  PropertyDataService
} from '../../../../services/property-data.service';
import {Sort} from '@angular/material/sort';
import {Page, PageInfo} from '../../../../models/page.model';
import {Subject, takeUntil} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {TagHeaderEvent, TagHeaderEventType, TagHeaderService} from '../tag-header/tag-header.service';
import {CategoryCellDto} from '../category-cell/category-cell.component';
import {ActionCellEvent, ActionCellEventType, ActionCellService} from '../action-cell/action-cell.service';
import {ActionCellDto} from '../action-cell/action-cell.component';
import {TagCellDto} from '../tag-cell/tag-cell.component';
import {TagCellEvent, TagCellEventType, TagCellService} from '../tag-cell/tag-cell.service';
import { CustomTranslateService } from '../../../../services/custom-translate.service';


@Component({
             selector: 'dms-tag-table',
             templateUrl: './tag-table.component.html',
             styleUrls: ['./tag-table.component.scss']
           })
export class TagTableComponent implements OnInit, OnDestroy {

  private readonly CATEGORY_FETCH_DEFAULT_SORT_PROPERTY = ParameterSortKey.KEY;
  private readonly CATEGORY_FETCH_DEFAULT_SORT_ORDER = ParameterSortOrder.ASC;
  private readonly PAGE_SIZE = 20;
  private readonly FIRST_PAGE = 0;

  private readonly SORT_KEY_MAP = {
    'property': ParameterSortKey.KEY
  };

  private readonly SORT_ORDER_MAP = {
    'asc': ParameterSortOrder.ASC,
    'desc': ParameterSortOrder.DESC,
  };

  private readonly stop$: Subject<void> = new Subject<void>();

  @ViewChild('scrollDiv', {read: ElementRef}) private readonly scrollDiv: ElementRef;
  @ViewChild('propertyTextInput', {static: true}) private readonly propertyTextInput: any;

  protected readonly displayedColumns: string[] = ['property', 'tag', 'action'];
  protected readonly dataSource: MatTableDataSource<PropertyKey | null> = new MatTableDataSource<PropertyKey | null>();
  protected readonly globalDataSource: MatTableDataSource<PropertyKey | null> = new MatTableDataSource<PropertyKey | null>();
  searchInput: string = '';

  private sortProperty = this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY;
  private sortOrder = this.CATEGORY_FETCH_DEFAULT_SORT_ORDER;
  private lastFetchPageInfo: PageInfo | undefined;

  constructor(private readonly tagHeaderService: TagHeaderService,
              private readonly actionCellService: ActionCellService,
              private readonly tagCellService: TagCellService,
              private readonly propertyDataService: PropertyDataService,
              private readonly translateService: CustomTranslateService,
              private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadDefaultProperties();
    this.subscribeTagHeaderEvent();
    this.subscribeActionElementEvent();
    this.subscribeTagCellEvent();
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  private readonly loadDefaultProperties = (): void => {
    this.setLastPageInfo(undefined);
    this.setSortProperty(this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY);
    this.setSortOrder(this.CATEGORY_FETCH_DEFAULT_SORT_ORDER);
    this.setDataSource([]);
    this.resetScroll();
    this.loadProperties({
                          extend: [ExtendValues.VALUES],
                          page: this.FIRST_PAGE,
                          size: this.PAGE_SIZE,
                          sort: `${this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY},${this.CATEGORY_FETCH_DEFAULT_SORT_ORDER}`,
                        });
  }

  private readonly resetScroll = (): void => {
    if (this.scrollDiv !== undefined) {
      this.scrollDiv.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  private readonly loadProperties = (parameters: GetParameters): void => {
    this.propertyDataService
        .getKeys$(parameters)
        .subscribe(this.onNewPageLoad);
  }

  private readonly subscribeTagHeaderEvent = (): void => {
    this.tagHeaderService
        .tagHeaderEvent$()
        .pipe(takeUntil(this.stop$))
        .subscribe((event: TagHeaderEvent & { payload?: string }) => {
          if (event.type === TagHeaderEventType.PROPERTY_CREATION_REQUEST) {
            this.loadCreationRow();
          } else if (event.type === TagHeaderEventType.PROPERTY_SEARCH_REQUEST) {
            this.onSearchRequest(event.payload || '');
          }
        });
  }

  private readonly onSearchRequest = (input : string): void => {
    this.setLastPageInfo(undefined);
    this.setSortProperty(this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY);
    this.setSortOrder(this.CATEGORY_FETCH_DEFAULT_SORT_ORDER);
    this.setDataSource([]);
    this.resetScroll();
    const searchTerm = input.toLowerCase();
    this.searchInput = searchTerm;
    const searchValue = this.constructSearchValue(searchTerm);
    this.loadProperties({
                          extend: [ExtendValues.VALUES],
                          page: this.FIRST_PAGE,
                          size: this.PAGE_SIZE,
                          sort: `${this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY},${this.CATEGORY_FETCH_DEFAULT_SORT_ORDER}`,
                          search: searchValue
                        });
  }

  private constructSearchValue(searchTerm: string): string {
    if (!searchTerm.trim()) {
      return '';
    }
    const searchProperties = ['propertyValues.value', 'key'];
    const cleanedSearch = searchTerm.trim();
    return searchProperties.map(property => `${property}:*${cleanedSearch}*`).join(' OR ');
  }

  private readonly loadCreationRow = (): void => {
    // adding null to data-source to interpret as a creation row
    this.setDataSource([null, ...this.dataSource.data]);
    this.resetScroll();
  }

  private readonly subscribeActionElementEvent = (): void => {
    this.actionCellService
        .actionCellEvent$()
        .pipe(takeUntil(this.stop$))
        .subscribe((event: ActionCellEvent) => {
          switch (event.type) {
            case ActionCellEventType.PROPERTY_DELETION_REQUEST:
              this.onPropertyDeletionRequestEvent(event);
              break;
            case ActionCellEventType.PROPERTY_CREATION_CANCEL:
              this.onPropertyCreationCancelEvent(event);
              break;
          }
        });
  }

  private readonly onPropertyDeletionRequestEvent = (event: ActionCellEvent): void => {
    const index = this.dataSource.data.findIndex(element => element.id === event.key.id);
    this.dataSource.data.splice(index, 1);
    this.dataSource._updateChangeSubscription();
  }

  private readonly onPropertyCreationCancelEvent = (event: ActionCellEvent): void => {
    this.setDataSource(this.dataSource.data.slice(1));
  }

  private readonly subscribeTagCellEvent = (): void => {
    this.tagCellService
        .tagCellEvent$()
        .pipe(takeUntil(this.stop$))
        .subscribe(this.onTagCellEvent);
  }

  private readonly onTagCellEvent = (event: TagCellEvent): void => {
    if (event.type === TagCellEventType.PROPERTY_CREATED) {
      this.onCreatedPropertyTagsEvent();
    }
  }

  private readonly onCreatedPropertyTagsEvent = (): void => {
    this.loadDefaultProperties();
  }

  protected onSortChange(sort: Sort): void {
    if (this.shouldLoadOnSort(sort)) {
      if (sort.direction === '') {
        if (!this.isDefaultSortImplemented()) {
          this.loadDefaultProperties();
        }
      } else {
        this.setDataSource([]);
        this.resetScroll();
        this.loadSort(this.SORT_KEY_MAP[sort.active],
                      this.SORT_ORDER_MAP[sort.direction]);
      }
    }
  }

  private readonly shouldLoadOnSort = (newSort: Sort): boolean => {
    return newSort.active !== this.sortProperty || newSort.direction !== this.sortOrder;
  }

  private readonly isDefaultSortImplemented = (): boolean => {
    return this.sortOrder === this.CATEGORY_FETCH_DEFAULT_SORT_ORDER && this.sortProperty === this.CATEGORY_FETCH_DEFAULT_SORT_PROPERTY;
  }

  protected loadSort = (property: ParameterSortKey, order: ParameterSortOrder): void => {
    this.setSortProperty(property);
    this.setSortOrder(order);
    this.loadProperties({
                          extend: [ExtendValues.VALUES],
                          page: this.FIRST_PAGE,
                          size: this.PAGE_SIZE,
                          sort: `${property},${order}`,
                        });
  }

  protected onScroll(): void {
    if (this.lastFetchPageInfo && !this.lastFetchPageInfo.last) {
      this.loadProperties({
                            extend: [ExtendValues.VALUES],
                            page: this.lastFetchPageInfo.number + 1,
                            size: this.PAGE_SIZE,
                            sort: `${this.sortProperty},${this.sortOrder}`,
                          });
    }
  }

  private readonly onNewPageLoad = (data: Page<PropertyKey>): void => {
    this.setLastPageInfo(data.page);
    const filteredData = this.applyEnhancedSearch(this.searchInput,data.content);
    this.updateDataSource(filteredData);
    if (this.globalDataSource.data.length === 0) {
      this.updateGlobalDataSource(filteredData);
    }
  }

  private applyEnhancedSearch(input: string, data: (PropertyKey | null)[]): (PropertyKey | null)[] {
    if (!input.trim()) {
      return data;
    }
    const combinedData = [...data, ...this.globalDataSource.data];
    const uniqueData = Array.from(new Set(combinedData.map(item => item?.id)))
      .map(id => combinedData.find(item => item?.id === id));
    return uniqueData
      .map(item => {
        if (!item) return null;

        const translatedKey = this.translate(item.key);
        const translatedValues = item.values?.map(value => ({
          ...value,
          value: this.translate(value.value)
        }));
        const keyMatches = translatedKey.toLowerCase().includes(input);
        const valueMatches = translatedValues.some(value => value.value.toLowerCase().includes(input));
        const filteredValues = keyMatches ? translatedValues : translatedValues.filter(value => value.value.toLowerCase().includes(input));
        return keyMatches || valueMatches ? { ...item, key: translatedKey, values: filteredValues } : null;
      })
      .filter(item => item !== null);
  }

  private readonly translate = (text: string): string => {
    return this.translateService.canTranslate(text) ? this.translateService.getTranslatedValue(text) : text;
  }

  private readonly updateDataSource = (data: (PropertyKey | null)[]): void => {
    this.setDataSource(this.dataSource.data.length > 0 ?
                       this.dataSource.data.concat(data) :
                       data);
    this.cdr.detectChanges();
  }

  private readonly updateGlobalDataSource = (data: (PropertyKey | null)[]): void => {
    const globalData = data.filter(item => item && item.global);
    this.setGlobalDataSource(globalData);
    this.cdr.detectChanges();
  }

  protected toCategoryCellDto(key?: PropertyKey): CategoryCellDto {
    if (key === null) {
      return null;
    }
    return {
      global: key.global,
      key: {
        key: key.key,
        id: key.id
      }
    };
  }

  protected toActionCellDto(key?: PropertyKey): ActionCellDto {
    if (key === null) {
      return null;
    }
    return {
      global: key.global,
      key: {
        id: key.id
      }
    };
  }

  protected toTagCellDto(key?: PropertyKey): TagCellDto {
    if (key === null) {
      return null;
    }
    return {
      global: key.global,
      key: {
        id: key.id
      },
      values: key.values.map(({id, value}) => {
        return {id, value};
      }),
    };
  }

  private readonly setSortProperty = (property: ParameterSortKey): void => {
    this.sortProperty = property;
  }

  private readonly setSortOrder = (order: ParameterSortOrder): void => {
    this.sortOrder = order;
  }

  private readonly setLastPageInfo = (page: PageInfo): void => {
    this.lastFetchPageInfo = page;
  }

  private readonly setDataSource = (data: (PropertyKey | null)[]): void => {
    this.dataSource.data = data;
  }

  private readonly setGlobalDataSource = (data: (PropertyKey | null)[]): void => {
    this.globalDataSource.data = data;
  }
}
