import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonConfig} from '../../shared/config/common-config';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {RightPanelService} from '../../../services/right-panel.service';
import {catchError, EMPTY, Observable, Subject, takeUntil, tap, throwError} from 'rxjs';
import {FileKeyProperties, PropertyValue} from '../../../models/fileKeyProperties.model';
import {DateRangeValidator, END_DATE_KEY, START_DATE_KEY} from '../../shared/date-range-validator';
import {TranslateService} from '@ngx-translate/core';
import {HandleErrorService} from '../../../services/handle-error.service';
import {ExtendValues, GetParameters, ParameterSortKey, ParameterSortOrder, PropertyDataService} from '../../../services/property-data.service';
import {FilterService} from '../../../services/filter.service';
import { TableConfig } from '../../shared/table/table-config/table-config';

@Component({
  selector: 'dms-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnDestroy {

  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;

  isShowFilter: boolean;
  pageNumber: number;
  filterValue: string;
  optionsList: FileKeyProperties[] = [];
  form: FormGroup;
  countries: PropertyValue[] = [];
  documentTypes: PropertyValue[] = [];
  departments: PropertyValue[] = [];
  languages: PropertyValue[] = [];
  errorMessage: string;
  startDate: string;
  endDate: string;
  tableConfig = new TableConfig();
  commonConfig = new CommonConfig();
  private isResetting = false;
  private readonly PAGE_SIZE = 20;
  private readonly FIRST_PAGE = 0;
  private readonly COLUMN_OPTIONS_SORT_PROPERTY = ParameterSortKey.ID;
  private readonly COLUMN_OPTIONS_SORT_ORDER = ParameterSortOrder.ASC;

  private readonly stop$: Subject<void> = new Subject<void>();

  constructor(private docMgmtService: DocMgmtService,
              private rightPanelService: RightPanelService,
              private propertyDataService: PropertyDataService,
              private translateService: TranslateService,
              private handleErrorService: HandleErrorService,
              private filterService: FilterService) {
    this.form = new FormGroup({
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
    }, {validators: DateRangeValidator.dateRangeValidator(new CommonConfig())});
  }

  formFieldConfigs: FormFieldConfig[] = [];

  ngOnInit() {
    this.tableConfig.infiniteScrollEnabled = true;
    this.rightPanelService.filterEvent.subscribe((event) => {
      if (event.showFilter) {
        this.isShowFilter = true;
        this.rightPanelService.isPanelOpen.next(true);
      }
      if (event.reset && !this.isResetting) {
        this.isShowFilter = false;
        this.onReset();
      }
    });

    this.loadKeys$({
      extend: [ExtendValues.VALUES],
      page: this.FIRST_PAGE,
      size: this.PAGE_SIZE,
      sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
    }).pipe(
      takeUntil(this.stop$)
    ).subscribe();

    this.loadFilterValues();
  }

  private loadKeys$(parameters: GetParameters): Observable<FileKeyProperties[]> {
    return this.propertyDataService.getValuesForAllKeys(parameters).pipe(
      tap((responses: FileKeyProperties[]) => this.handleFileKeyPropertiesResponse(responses)),
      catchError(() => {
        this.handleErrorService.displayErrorDialog(this.translateService.instant('unableToLoad'), '');
        return EMPTY;
      })
    );
  }

  private handleFileKeyPropertiesResponse(responses: any): void {
    this.optionsList = [...this.optionsList, ...responses];
    responses.forEach(response => {
      if (!this.form.contains(response.key)) {
        this.form.addControl(response.key, new FormControl([]));
      }
    });
    this.formFieldConfigs = [
      ...this.formFieldConfigs,
      ...responses.map(response => ({
        name: response.key,
        label: response.key,
        placeholderVisible: true,
        options: response.values
      }))
    ];
  }

  onScroll() {
    if (this.tableConfig.infiniteScrollEnabled) {
      this.commonConfig.pageNumber++;
      this.loadKeys$({
        extend: [ExtendValues.VALUES],
        page: this.commonConfig.pageNumber,
        size: this.PAGE_SIZE,
        sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
      }).pipe(
        takeUntil(this.stop$)
      ).subscribe();
    }
  }

  checkPlaceHolder(controlName: string) {
    const fieldConfig = this.formFieldConfigs.find(field => field.name === controlName);
    if (fieldConfig) {
      const controlValue = this.form.get(controlName)?.value;
      fieldConfig.placeholderVisible = !controlValue || controlValue.length === 0;
    }
  }

  hidePlaceHolder(controlName: string) {
    const fieldConfig = this.formFieldConfigs.find(field => field.name === controlName);
    if (fieldConfig) {
      fieldConfig.placeholderVisible = false;
    }
  }

  private loadFilterValues(): void {
    const savedFilterValues = localStorage.getItem('filterValues');
    if (savedFilterValues) {
      const formValues = JSON.parse(savedFilterValues);
      this.formFieldConfigs.forEach(fieldConfig => {
        if (formValues[fieldConfig.name] && formValues[fieldConfig.name].length > 0) {
          fieldConfig.placeholderVisible = false;
        }
      });
      if (formValues.startDate) {
        this.startDate = this.formatISODateToString(formValues.startDate);
        this.form.get(START_DATE_KEY).setValue(this.startDate);
      }
      if (formValues.endDate) {
        this.endDate = this.formatISODateToString(formValues.endDate);
        this.form.get(END_DATE_KEY).setValue(this.endDate);
      }

      if (this.formFieldConfigs.length > 0) {
        this.form.patchValue(formValues);
      } else {
        setTimeout(() => this.form.patchValue(formValues), 100);
      }
    }
  }

  private formatISODateToString(isoDate: string): string {
    const dateObj = new Date(isoDate);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  onStartDateChanged(startDateValue: Date): void {
    this.form.get(START_DATE_KEY).setValue(startDateValue);
  }

  onEndDateChanged(endDateValue: Date): void {
    this.form.get(END_DATE_KEY).setValue(endDateValue);
  }

  onFilter() {
    if (this.formValidation()) {
      const formValues = this.form.value;
      this.saveFilterValues();
      const propertyValues = Object.keys(formValues)
      .filter(key => key !== 'startDate' && key !== 'endDate')
      .map(key => formValues[key] || [])
      .flat();
      const startDate = formValues.startDate ? new Date(formValues.startDate) : null;
      startDate?.setHours(0, 0, 0, 0);

      const endDate = formValues.endDate ? new Date(formValues.endDate) : null;
      endDate?.setHours(23, 59, 59, 999);

      this.filterService.setStartDate(startDate);
      this.filterService.setEndDate(endDate);
      this.filterService.setPropertyValuesIds(propertyValues);

      this.docMgmtService.getFileMetadata({
        page: 0,
        filterValue: this.filterValue,
        startDate: startDate?.getTime(),
        endDate: endDate?.getTime(),
        propertyValues: propertyValues
      });
      this.rightPanelService.filterEvent.emit({showFilter: true, reset: false, isFilterApplied: true});
    }
  }

  private formValidation(): boolean {
    const errorKeys = ['startDateGreaterThanEndDate', 'startDateNotSelected', 'timeExceed'];
    for (const key of errorKeys) {
      if (this.form.errors?.[key]) {
        this.errorMessage = this.translateService.instant(key);
        return false;
      }
    }
    return true;
  }

  private saveFilterValues(): void {
    const formValues = this.form.value;
    localStorage.setItem('filterValues', JSON.stringify(formValues));
  }

  onReset() {
    this.form.reset();
    this.startDate = null;
    this.endDate = null;
    this.startDateInput.nativeElement.value = '';
    this.endDateInput.nativeElement.value = '';
    this.filterService.setStartDate(null);
    this.filterService.setEndDate(null);
    this.filterService.setPropertyValuesIds([]);
    localStorage.removeItem('filterValues');

    this.formFieldConfigs.forEach(fieldConfig => {
      fieldConfig.placeholderVisible = true;
    });

    this.rightPanelService.filterEvent.emit({showFilter: false, reset: false, isFilterApplied: false});
    this.docMgmtService.getFileMetadata();
    this.isResetting = false;
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}

interface FormFieldConfig {
  name: string;
  label: string;
  placeholderVisible: boolean;
  options: PropertyValue[];
}
