<div class="detailPanel">
    <span class="detailPanelHeading">
      {{ "filter" | translate }}
    </span>
</div>
<div class="panel-content">
  <div class="date-elements">
    <ng-container>
      <span class="datepicker-span-start">
        <modus-date-picker>
          <modus-date-input
            #startDateInput
            type="start"
            label="{{ 'startDate' | translate }}"
            format="dd-mm-yyyy"
            allowed-chars-regex="[\d-]"
            show-calendar-icon="true"
            [value]="startDate"
            placeholder="{{ 'selectStartDate' | translate }}"
            (valueChange)="onStartDateChanged(startDateInput.value)"
            [attr.error-text]="errorMessage"
          >
          </modus-date-input>
        </modus-date-picker>
      </span>
      <span class="datepicker-span">
        <modus-date-picker>
          <modus-date-input
            #endDateInput
            type="end"
            label="{{ 'endDate' | translate }}"
            format="dd-mm-yyyy"
            allowed-chars-regex="[\d-]"
            show-calendar-icon="true"
            placeholder="{{ 'selectEndDate' | translate }}"
            [value]="endDate"
            (valueChange)="onEndDateChanged(endDateInput.value)"
          >
          </modus-date-input>
        </modus-date-picker>
      </span>
    </ng-container>
  </div>
  <form [formGroup]="form">
      <div class="property" *ngFor="let field of formFieldConfigs">
          <mat-label class="property-label">{{ field.label | translate }}</mat-label> <br>
          <mat-form-field>
              <mat-select
                      [formControlName]="field.name"
                      multiple
                      (click)="hidePlaceHolder(field.name)"
                      (blur)="checkPlaceHolder(field.name)"
                      [placeholder]="field.placeholderVisible ? ('selectFromOptions' | translate) : ''"
                      (selectionChange)="checkPlaceHolder(field.name)"
                      class="property-select"
                      disableOptionCentering>

                  <mat-option *ngFor="let option of field.options" [value]="option.id">
                      {{ option.value | translate }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
  </form>
  <div class="bottom">
    <span><modus-button
      class="reset-filter"
      button-style="outline"
      color="secondary"
      (click)="onReset()"
    >{{ "reset" | translate }}</modus-button
    ></span>
    <span><modus-button
      button-style="fill"
      color="primary"
      (click)="onFilter()"
    >{{ "filter" | translate }}</modus-button
    ></span>
  </div>
</div>
